import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Ruwbouw en renovatie in Limburg" />

    <h1>M&S Bouwwerken, uw partner voor ruwbouw en renovatie in Limburg</h1>

    <p>M&S bouwwerken heeft meer dan 30 jaar ervaring als aannemer voor nieuwbouw en renovaties.
      Met onze persoonlijke aanpak en advies is uw project in goede handen.</p>

    <p>Heeft u bouw en of renovatieplannen? In een verkennend gesprek bespreken we graag de details van je project.</p>
    <Link to={"/realisaties/"} className={"c-button"}>Ontdek onze realisaties</Link>


    <h2>Offerte op maat</h2>
    <p>Van zodra uw bouwplan is afgerond is het tijd om een betrouwbare aannemer aan te stellen.
        Andry en Gesuino van M&S bouwwerken komen graag bij u langs en nemen uitgebreid de tijd om uw plannen te overlopen.
        Van zodra alle vragen en wensen zijn overlopen ontvangt u vrijblijvend een gedetailleerde offerte.</p>

      <Link to={"/contact/"} className={"c-button"}>Neem vrijblijvend contact met ons op</Link>

  </Layout>
)

export default IndexPage
